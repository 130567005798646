import * as React from "react"
import { Link } from "gatsby"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"

import * as styles from "./header.module.css"
import { MobileNav } from "../MobileNav/MobileNav"

const Header = ({ invert, title }) => {
    const [showNav, setShowNav] = React.useState(false)

    const showMobileNav = () => {
        document.getElementsByTagName("body")[0].style = "overflow: hidden"
        setShowNav(true)
    }

    const hideMobileNav = () => {
        document.getElementsByTagName("body")[0].style = "overflow: scroll"
        setShowNav(false)
    }

    const enableScroll = () => {
        document.getElementsByTagName("body")[0].style = "overflow: scroll"
    }

    return (
        <header
            className={
                invert
                    ? `${styles.header} ${styles.invert}`
                    : `${styles.header}`
            }
        >
            <div
                className={
                    showNav || invert
                        ? `${styles.headerNav} ${styles.invert}`
                        : `${styles.headerNav}`
                }
            >
                <Link to="/" className={styles.homeLink}>
                    {!!title ? `${title}` : `Silver Oaks`}
                </Link>
                <nav className={styles.navLinks}>
                    <Link to="/about">About Us</Link>
                    <Link to="/products">Products</Link>
                    <Link to="/contact-us">Contact</Link>
                </nav>
                <div
                    className={
                        showNav || invert
                            ? `${styles.navMenuToggle} ${styles.invert}`
                            : `${styles.navMenuToggle}`
                    }
                >
                    {showNav ? (
                        <CloseIcon
                            style={{ cursor: "pointer" }}
                            fontSize="large"
                            onClick={hideMobileNav}
                        />
                    ) : (
                        <MenuIcon
                            style={{ cursor: "pointer" }}
                            fontSize="large"
                            onClick={showMobileNav}
                        />
                    )}
                </div>
            </div>

            <MobileNav showNav={showNav} hideNav={enableScroll} />
        </header>
    )
}

export default Header
