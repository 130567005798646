import React from "react"
import { Link } from "gatsby"

import * as styles from "./MobileNav.module.css"

export const MobileNav = ({ showNav, hideNav }) => {
    let navClassName = showNav
        ? `${styles.navigationMenu} ${styles.show}`
        : `${styles.navigationMenu}`

    return (
        <div className={navClassName}>
            <div className={styles.links}>
                <Link to="/" onClick={hideNav}>Home</Link>
                <Link to="/about" onClick={hideNav}>About Us</Link>
                <Link to="/products" onClick={hideNav}>Products</Link>
                <Link to="/contact-us" onClick={hideNav}>Contact</Link>
            </div>
        </div>
    )
}
