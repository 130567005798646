import React from 'react'
import * as styles from "./footer.module.css"

const Footer = () => {
    return (
        <footer className={styles.container}>
            <div className={styles.containerContent}>
                <p>Silver Oaks International Limited</p>

                <p style={{fontStyle: "italic"}}>&copy; Copyright Sannan Hafeez 2021</p>
            </div>
        </footer>
    )
}

export default Footer
